import React, { useState } from 'react';
import { Alert, Button, TextField } from '@mui/material';
import './styles.css';

function LoveCalculator() {
    const [firstName, setFirstName] = useState('');
    const [secondName, setSecondName] = useState('');

    const handleChangeFirstName = (e) => {
        setFirstName(e.target.value);
    };

    const handleChangeSecondName = (e) => {
        setSecondName(e.target.value);
    };
    const handleClear = (e) => {
        setFirstName('');
        setSecondName('');
    }

    const handleResult = (e) => {
        e.preventDefault(); 

        

        if ((firstName == "Junaid" && secondName == "Arisha") 
            || (firstName == "Arisha" && secondName == "Junaid") 
            || (firstName == "junaid" && secondName == "arisha")
            || (firstName == "arisha" && secondName == "junaid")
            || (firstName == "Noni" && secondName == "Monu")
            || (firstName == "noni" && secondName == "monu")
            ||  (firstName == "Monu" && secondName == "Noni")
            || (firstName == "monu" && secondName == "noni")) {
            alert(`${firstName} and ${secondName}'s love result is 100%`);

        
        setFirstName('');
        setSecondName('');
        } else {
            const newResult = Math.trunc(Math.random() * 100);
            alert(`${firstName} and ${secondName}'s love result is ${newResult}%`);
    
            
            setFirstName('');
            setSecondName('');
        }
    };

    return (
        <div className="centered-container">
            <div className="content">
                <h2>Love Calculator</h2>
                <form onSubmit={handleResult}>
                    <div>
                        <TextField
                            required
                            name="firstBox"
                            id="standard-basic"
                            label="Enter Your Name"
                            variant="standard"
                            value={firstName}
                            onChange={handleChangeFirstName}
                        />
                    </div>
                    <br />
                    <div>
                        <TextField
                            required
                            name="secondBox"
                            id="standard-basic"
                            label="Enter Your Lover's Name"
                            variant="standard"
                            value={secondName}
                            onChange={handleChangeSecondName}
                        />
                    </div>
                    <br />
                    <div className="form-group">
                        <Button variant="contained" type="submit">Check Result</Button>
                    </div>
                    
                </form>
                <div className="form-group">
                        <button onClick={handleClear} type="clear">Clear Values</button>
                    </div>
            </div>
        </div>
    );
}

export default LoveCalculator;
