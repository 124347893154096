
import logo from './logo.svg';
import './App.css';
import LoveCalculator from './component/LoveCalculator';
import { Score } from '@mui/icons-material';
import LoveResult from './component/LoveResult';

function App() {
  return (
    <div>
          <LoveCalculator />
          {/* <LoveResult /> */}
    </div>
  );
}

export default App;
